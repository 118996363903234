$('.hero__slider').slick(
    {
        arrows: false,
        dots: true,
        infinite: true,
        mobileFirst: true,
    }
);

$('.recipes__slider').slick(
    {
        arrows: true,
        // arrows: false,
        dots: true,
        infinite: true,
        mobileFirst: true,
    }
);

$('.careers__slider').slick(
    {
        arrows: true,
        dots: true,
        infinite: true,
        mobileFirst: true,
        // appendArrows: '.careers__slider',
        // prevArrow: '<button class="slider-arrow-p"></button>',
        // nextArrow: '<button class="slider-arrow-n"></button>'
    }
);


