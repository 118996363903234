
let showMore = document.querySelectorAll('.show')

showMore.forEach(el => {
    el.addEventListener('click', e => {
        e.preventDefault()

        let parent = e.target.parentElement
        let text = parent.querySelector('.text-wrapper__inner')

        text.classList.toggle('open')
        if (e.target.innerHTML == 'Show more') {
            e.target.innerHTML = 'Show less'
        } else {
            e.target.innerHTML = 'Show more'
        }

    })
})