function popup() {
  const openPopupBtn = document.getElementById('open-popup')
  const popup = document.getElementById('popup')
  const popupBg = document.getElementById('popupBg')
  const popupClose = document.getElementById('popupClose')

  openPopupBtn.addEventListener('click', (e) => {
    e.preventDefault()
    popup.classList.add('popup-wrapper-opened')
    popupBg.classList.add('popup-bg-opened')
  })

  popupClose.addEventListener('click', (e) => {
    e.preventDefault()
    popup.classList.remove('popup-wrapper-opened')
    popupBg.classList.remove('popup-bg-opened')
  })
}

popup()